import { Button, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { legal, about, help } from './footerData'
import './styles.css'
import Cookies from 'universal-cookie';
import SocialMedia from './socialMedia'
import { Link } from 'react-router-dom'

function Footer() {
    const cookie = new Cookies();

    return (
        <div className='footerWrapper'>
            <p>© Copy Right-2022 All rights reserved.</p>
        </div>
    )
}

export default Footer