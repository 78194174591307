import { Box, Container, Divider, Grid } from '@mui/material'
import React from 'react'
import './style.css'
import wallet from '../../assets/images/wallet.webp'
import support from '../../assets/images/support.webp'
import security from '../../assets/images/shield.webp'

function MobileApp() {
    const data = [
        {
            icon: wallet,
            title: 'Over 50 million wallets opened'
        },
        {
            icon:security ,
            title: 'Secure, simple, and safe'
        },
        {
            icon: support,
            title: '24/7 Support'
        },

    ]
    return (
        <>
            <Container>
                <Box className='mobileAppContainer'>
                    {
                        data?.map((d)=>{
                            return(
                                <Box className='mobileAppContentContainer'>
                                    <img src={d.icon} width={80}/>
                                    <h2>{d.title}</h2>
                                </Box>
                            )
                        })
                    }
                </Box>
            </Container>
            <Divider style={{ margin: "32px" }}/>
        </>
    )
}

export default MobileApp