import React, { useEffect, useState } from 'react'
import './styles.css'
import { Box, CircularProgress } from '@mui/material'
import { getShuffledItems } from '../../hooks/shuffle'

function Features({coins}) {
    const [trendyCoins , setTrendyCoins] = useState()
    const [newCoins , setNewCoins] = useState()
    const [topGainersCoins , seTopGainersCoins] = useState()

    useEffect(() => {
        setTrendyCoins(getShuffledItems(coins))
        setNewCoins(getShuffledItems(coins))
        seTopGainersCoins(getShuffledItems(coins))
    }, [coins])
    
    return (
        <Box className='featuresWrapper'>
            <Box className='featuresCard'>
                {
                    coins.length !== 0 ?
                    <>
                        <h2>Trendy Coins</h2>
                        {
                            trendyCoins?.map((coin)=>{
                                return (
                                    <Box className='featuresCardContent'>
                                        <Box className='featuresCardContentBox'>
                                            <img src={coin.image} width={50}/> 
                                            <Box>
                                                <h3 style={{marginBottom:'0px'}}>{coin?.name}</h3>
                                                <p style={{marginTop:"8px"}}>${coin?.current_price}</p>
                                            </Box>
                                        </Box>
                                    </Box>
                                )
                            })
                        }
                    </>
                    :
                    <Box className='featuresLoading'>
                        <CircularProgress />
                    </Box>
                }
            </Box>
            <Box className='featuresCard'>
                {
                    coins.length !== 0 ?
                    <>
                        <h2>New Coins</h2>
                        {
                            newCoins?.map((coin)=>{
                                return (
                                    <Box className='featuresCardContent'>
                                        <Box className='featuresCardContentBox'>
                                            <img src={coin.image} width={50}/> 
                                            <Box>
                                                <h3 style={{marginBottom:'0px'}}>{coin?.name}</h3>
                                                <p style={{marginTop:"8px"}}>${coin?.current_price}</p>
                                            </Box>
                                        </Box>
                                    </Box>
                                )
                            })
                        }
                    </>
                    :
                    <Box className='featuresLoading'>
                        <CircularProgress />
                    </Box>
                }
            </Box>
            <Box className='featuresCard'>
                {
                    coins.length !== 0 ?
                    <>
                        <h2>Top Gainers</h2>
                        {
                            topGainersCoins?.map((coin)=>{
                                return (
                                    <Box className='featuresCardContent'>
                                        <Box className='featuresCardContentBox'>
                                            <img src={coin.image} width={50}/> 
                                            <Box>
                                                <h3 style={{marginBottom:'0px'}}>{coin?.name}</h3>
                                                <p style={{marginTop:"8px"}}>${coin?.current_price}</p>
                                            </Box>
                                        </Box>
                                    </Box>
                                )
                            })
                        }
                    </>
                    :
                    <Box className='featuresLoading'>
                        <CircularProgress />
                    </Box>
                }
            </Box>
        </Box>
    )
}

export default Features