import { Grid } from '@mui/material'
import React, { useState } from 'react'
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import SigninFields from './signinFields'
import './styles.css'

function Signin() {
    const [errormessage, setErrormessage] = useState('')
    return (
        <>
            <Grid container fluid >
                <Grid xs={12} className="SigninWrapper">
                    <div className='SigninContainer'>
                        <div className='SigninArrowBack'>
                            <Link to="/" style={{ color: 'black', textDecoration: 'none' }}>
                                <BsFillArrowLeftCircleFill size={25} style={{ color: '#2793FF' }} />
                            </Link>
                        </div>
                        <div className='SigninContainerChildOne'>
                            <h1>Sign in</h1>
                          
                            <SigninFields setErrormessage={setErrormessage} errorMessage={errormessage}/>
                            <p className='SideSigninSignup'>Don't have an account.
                                {" "}
                                <Link to="/signup" style={{ color: 'var(--white)' }}>Sign up</Link>
                            </p>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default Signin