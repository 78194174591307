import { Box, Container, Grid } from '@mui/material'
import React from 'react'
import './styles.css'
import img from '../../assets/images/what-is-bitcoin.webp'
import img2 from '../../assets/images/safe-and-secure.webp'
import setting from '../../assets/images/setting.webp'
import share from '../../assets/images/share.webp'
import walletRound from '../../assets/images/wallet-round.webp'
import safe1 from '../../assets/images/safe-secure-list-img-1.webp'
import safe2 from '../../assets/images/safe-secure-list-img-2.webp'
import safe3 from '../../assets/images/safe-secure-list-img-3.webp'
import safe4 from '../../assets/images/safe-secure-list-img-4.webp'
import safe5 from '../../assets/images/safe-secure-list-img-5.webp'

function CryptoCurrency() {
    const data = [
        {
            icon: setting,
            title: "Seamless and quick trading.",
        },
        {
            icon: share,
            title: "Exchange and receive thousands of assets.",
        },
        {
            icon: walletRound,
            title: "Self-custody and multi-chain support.",
        },
    ]
    const data2 = [
        {
            icon: safe1,
            title: "Your keys, your control.",
        },
        {
            icon: safe2,
            title: "Encrypted key backup",
        },
        {
            icon: safe3,
            title: "Scam prevention",
        },
        {
            icon: safe4,
            title: "Multisig support",
        },
        {
            icon: safe5,
            title: "Externally audited",
        },
    ]

    return (
        <>
            <Container className='CryptoCurrencyWrapper'>
                <Grid container>
                    <Grid item xs={12} md={6} className='CryptoCurrencyContent'>
                        <h1>Gain control over your finances.</h1>
                        <Box className='CryptoCurrencyContentData'>
                            {
                                data?.map((d) => {
                                    return (
                                        <Box className='CryptoCurrencyContentData2'>
                                            <img src={d.icon} width={40} alt='4'/>
                                            <p>{d.title}</p>
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    </Grid>
                    <Grid
                        item xs={12} md={6}
                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                    >
                        <img src={img} width='80%' height='85%' alt='2'/>
                    </Grid>
                </Grid>
            </Container>

            <Container className='CryptoCurrencyWrapper'>
                <Grid container>
                    <Grid
                        item xs={12} md={6}
                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                    >
                        <img src={img2} width='80%' height='50%' alt='1'/>
                    </Grid>
                    <Grid item xs={12} md={6} className='CryptoCurrencyContent'>
                        <h1>Secure and protected.</h1>
                        <Box className='CryptoCurrencyContentData'>
                            {
                                data2?.map((d) => {
                                    return (
                                        <Box className='CryptoCurrencyContentData2'>
                                            <img src={d.icon} width={40} alt='3'/>
                                            <p>{d.title}</p>
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default CryptoCurrency