import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import Navbar from '../../components/navbar'
import Footer from '../../sections/footer'
import Cookies from 'universal-cookie';

function UserLayout({ user }) {
    const cookie = new Cookies();
    return (
        cookie.get('token') ?
            <>
                <Navbar user={user} />
                <Outlet />
                {/* <Footer /> */}
            </>
            :
            <>
                <Navigate to="/" replace={true} />
            </>
    )
}

export default UserLayout