export const getShuffledItems = (arr, numItems = 5) =>{
    // Copy the array to avoid modifying the original
    let shuffledArr = arr.slice();
    
    // Shuffle the array using Fisher-Yates algorithm
    for (let i = shuffledArr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArr[i], shuffledArr[j]] = [shuffledArr[j], shuffledArr[i]];
    }
  
    // Return the first numItems elements from the shuffled array
    return shuffledArr.slice(0, numItems);
  }