import { Box, Button, Container, Divider, Grid } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import './styles.css'
import Cookies from 'universal-cookie';
import heroImage from '../../assets/images/hero-image.webp'

function Body() {
    const cookie = new Cookies()

    return (
        <>
            <Grid container className='bodyWrapper'>
                <Grid item xs={12} md={6}>
                    <Box className='bodyContainer'>
                        <p className='bodyTitle'>Get your first <br /> coin now.</p>
                        <p className='bodyDesc'>Simplify your Bitcoin & crypto transactions with our safe and comprehensive app.</p>
                            <Link to='/markets'>
                                <Button variant='contained' className='bodyButton'>Pricing Today.</Button>
                            </Link>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <img src={heroImage} className='heroImage' />
                </Grid>
            </Grid>
            <Divider style={{ margin: "32px" }} />
        </>
    )
}

export default Body