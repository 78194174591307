import React from 'react'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Snackbars(props) {

    return (
        <Snackbar open={props.open} autoHideDuration={props.duration} onClose={props.close} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
            <Alert severity={props.severity} sx={{ width: '100%' }} onClose={props.close}>
                {props.text}
            </Alert>
        </Snackbar>
    )
}

export default Snackbars